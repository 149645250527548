import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { pathToLink } from "../helper";
import Tag from "./Tag";
{
  /* <div class="p-2 m-4 w-16 h-16 text-center bg-gray-700 rounded-full text-white float-right fd-cl group-hover:opacity-25">
<span class="text-base tracking-wide  font-bold border-b border-white font-sans"> 12</span>
 <span class="text-xs tracking-wide font-bold uppercase block font-sans">April</span>
</div> */
}
export default ({
  title,
  featured_media,
  excerpt,
  slug,
  tags,
  categories,
  className = "md:w-1/3",
  fluid = false,
}) => {
  return (
    <div className={`card  m-2 ${className}`}>
      <div className="rounded overflow-hidden shadow-lg bg-white h-full flex flex-col justify-between">
        <div>
        {featured_media && (
          <Link to={`/${slug}`}>
            <Img
              className="h-64 w-full object-cover object-center"
              fluid={featured_media.localFile.childImageSharp.fluid}
            />
          </Link>
        )}

        <div className="p-4">


          {categories &&
            categories.map((category) => (
              <Link to={pathToLink(category.path)} key={category.path}>
                <div className="uppercase inline-block py-1 px-3 my-4 bg-red-dark text-white text-sm font-medium tracking-widest">
                  {category.name}
                </div>
              </Link>
            ))}

          <div className="font-family-louize text-2xl md:text-3xl mb-2 hover:underline">
            <Link to={`/${slug}`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </Link>
          </div>

          {excerpt && (
            <p className="text-gray-700 text-base">
              <div dangerouslySetInnerHTML={{ __html: excerpt }} />
            </p>
          )}
        </div>
        </div>

        {tags && (
          <div className="px-6 py-4">
            {tags.map((tag) => (
              <Tag key={tag.path} {...tag} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
