import React from "react";

export default ({
  type,
  name,
  description,
  tag,
}) => {
 
  return (
    <div className="mx-auto bg-red-dark">
      <div
        className={`bg-cover bg-top  h-auto text-blue-navy py-24 lg:px-10 object-fill`}
      >
        <div className="container">
          {type && (
            <p className="font-bold text-sm uppercase text-white">{type}:</p>
          )}
     
          <h1 className="text-6xl font-bold text-cream line-through">
            {name}
          </h1>
         

          {description && (
            <div className="bg-white rounded-lg p-4 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10">
              <div
                className="text-sm text-gray-600 whitespace-pre-line"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </div>
          )}
        </div>
      </div>

    </div>
  );
};
