import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import Layout from "../components/layout";
import Card from "../components/Card";
import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";

const CategoryPage = ({ data, pageContext }) => {
  const posts = data.allWordpressPost.edges.map(({ node }) => node);
  return (
    <Layout>
      <SEO
        keywords={[pageContext.category.name]}
        title={`Tudo sobre ${pageContext.category.name} animal | Dicas de alimentação, higiene e comportamento`}
        image={get(posts, "[0].featured_media.source_url", null)}
        pathname={`categoria/${pageContext.category.slug}`}
      />

      <MiniHero
        type="categoria"
        name={pageContext.category.name}
        description={pageContext.category.description}
      />

   

      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap -m-4">
          {posts.map((node) => (
            <Card key={node.slug} {...node} />
          ))}
        </div>
      </div>


    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String) {
    allWordpressPost(
      limit: 24
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          slug
          author {
            name
          }
          tags {
            name
            slug
            path
          }
          categories {
            name
            slug
            path
          }
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CategoryPage;
